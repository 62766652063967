@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Jura:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/*::-webkit-scrollbar {
  width: 10px;
}

/ Track /
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/ Handle /
::-webkit-scrollbar-thumb {
  background: #888;
}

/ Handle on hover /
::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/


:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #362727 -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.errmsg {
  color: firebrick;
}

input, button {
  font: inherit;
}

#root {
  min-height: 100vh;
  display: flex;
  /*padding: 1rem;*/
}

/*section {
  width: min(100%, 900px);
}*/

a, a:visited {
  color: whitesmoke;
}

a:hover, a:focus {
  color: rgba(245, 245, 245, 0.9);
}

/* || LOGIN */

.login form {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 1rem;
}

.login input, .login button {
  padding: 0.5em;
  border-radius: 15px;
}

/* || PAGES */

.public, .welcome, .users {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.public main {
  flex-grow: 1;
  border-bottom: 2px solid #fff;
  padding: 1rem;
}

.users ul {
  list-style-type: none;
}

body {
  font: 1.5rem 'Nunito', sans-serif;;
  min-height: 100vh;
  background-color: #141b2d;
  color: #fff;
}


.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #ffffff -43.27%,
    #d3bef5 -21.24%,
    #2bd152 12.19%,
    #11a734 29.82%,
    #049e28 51.94%,
    #08b430 90.29%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #ffffff -43.27%,
    #d3bef5 -21.24%,
    #2bd152 12.19%,
    #11a734 29.82%,
    #049e28 51.94%,
    #013b0f 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #1c1c1d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.bg-primary {
  background: linear-gradient(125.17deg, #272727 0%, #141b2d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #c4f4cd 0%, #fc6767 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}